<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <md-icon>list</md-icon>
            <h3 class="pl-2 text-uppercase">Online & Blogs</h3>
          </div>
          <div class="flex">
           <SwitchComponent :items="[{id: 1, name: 'Today'}, {id: 2, name: 'Weekly'}, {id: 3, name: 'Monthly'}]"/>
          </div>
        </div>
      </template>
      <CardBody class="p-3">
        <div class="mb-3 flex justify-between flex-wrap justify-sm-center justify-xs-center">
          <SelectComponent placeholder="Short By Date" class="w-64 m-2" />
          <div class="align-center flex">
            <SearchBox placeholder="search" class="w-64 p-2 m-2" />
          </div>
        </div>
        <md-divider></md-divider>
        <Table :items="onlineAndBlogs" @onSelect="onSelect" hover-action>
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-sort-by="blogId" md-label="BLOG ID" class="text-victoria">{{ item.blogId }}</md-table-cell>
            <md-table-cell md-sort-by="blogName" md-label="BLOG NAME" class="text-victoria">{{ item.blogName }}</md-table-cell>
            <md-table-cell md-sort-by="related" md-label="RELATED">{{ item.related }}</md-table-cell>
            <md-table-cell md-sort-by="medium" md-label="MEDIUM">{{ item.medium }}</md-table-cell>
            <md-table-cell md-sort-by="createdAt" md-label="CREATED AT">{{ item.createdAt }}</md-table-cell>
            <md-table-cell md-sort-by="createdBy" md-label="CREATED BY">{{ item.createdBy }}</md-table-cell>
            <span class="action">
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">edit</md-icon>
            </span>
          </template>
        </Table>
      </CardBody>
    </Card>
    <Dialog>
      <CreateNewNotice />
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    SelectComponent,
    SwitchComponent,
    } from "@/components";
import CreateNewNotice from "@/components/molecule/communication/CreateNewNotice";
import SearchBox from "@/components/atom/SearchBox";
import { onlineAndBlogs } from "@/data/communication/leads";
import { mapMutations } from "vuex";

export default {
    components: {
        Card,
        Table,
        Dialog,
        CardBody,
        SearchBox,
        CreateNewNotice,
        SelectComponent,
        SwitchComponent
  },
  data() {
      return {
          onlineAndBlogs: onlineAndBlogs
      }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onSelect() {

    }
  },
}
</script>